.Desktop .editUser-page{
    margin: auto;
    max-width: 480px;
}

.Mobile .editUser-page, .Tablet .editUser-page{
    margin-bottom: 100px;
}

.message-box .card-component{
    background-color: #64c764;
    animation: fadeIn 1s ease-in-out;
}

.card-component .message{
    font-size: 1.3em;
    color: white;
}

.card-component.error{
    background-color: #b74b4b;
}

@keyframes fadeIn {
    from {opacity: 0; visibility: hidden;}
    to {opacity: 1; visibility: visible;}
}

