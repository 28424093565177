.Puntos-Page {
  background-color: rgba(25, 25, 25, 0.5);
  margin: 8px 8px 128px 8px;
  border-radius: 8px;
}

.Puntos-Page table {
  width: calc(100% - 16px);
  background-color: white;
  margin: 8px;
}

.Puntos-Page th,
td,
tr {
  margin: 4px;
  padding: 8px;
  text-align: center;
}

.Puntos-Page td {
  font-size: .8rem;
}


.Puntos-Page table thead {
  background-color: var(--violeta);
  color: white;
}

tbody {
  background-color: white;
  overflow-y: scroll;

}

tbody tr:nth-child(odd) {
  background-color: #fafafa;
}

tfoot {
  background-color: var(--violeta_oscuro);
  color: white;
}


.Desktop .Puntos-Page {
  max-width: 632px;
  margin: auto;
  padding: 16px;
}