.premios-page {
  background-color: rgba(0, 0, 0, 0.5);
  margin: 32px 8px 128px 8px;
  padding: 8px 0;
  border-radius: 8px;

}

.PuntosImage {
  width: 80vw;
  margin: auto;
  display: block;
}

.caja-puntos {
  background-color: var(--rojo_claro);
  color: white;
  text-align: center;
  margin: 32px 8px;
  padding: 8px;
  border-radius: 8px;
  font-size: 1.2rem;
}

.premios-page ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  color: white;
}

.premios-page ul li div {
  width: 100%;
  margin-left: 16px;
}

.premios-page ul li p {
  margin-top: 8px;
}

.premios-page ul li img {
  max-height: 64px;
  margin-left: 16px;
}

.premios-page ul button {
  background: var(--violeta) !important;
  max-width: 100px;
  margin-right: 8px;
  font-size: .8rem;
  height: 44px;
  line-height: 44px;
}

.Desktop .PuntosImage {
  max-width: 400px;
  padding-top: 64px;
}

.Desktop .premios-page {
  max-width: 632px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
}

.Mobile .card-component {
  min-width: calc(100vw - 32px);

}

.card-head {
  background-color: #ddd;
  border-bottom: 1px solid #ccc;
  margin: -16px -16px 0 -16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 12px 16px;
  text-align: center;
}

.card-component button:disabled {
  opacity: .5;
}

.Validancia input {
  border: 1px solid #ddd !important;
  background-color: white;
  text-align: center;
  font-size: 2rem;
  height: 64px;
}

.canje-info {
  background-color: #f5f5f5;
  margin-top: 16px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #f6f6f6;
}

.canje-info img {
  width: 54px;
  height: 54px;
  margin-right: 8px;
}

.canje-info h4 {
  font-size: .8rem;
}

.canje-info p {
  text-align: left;
  margin-top: 16px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
}