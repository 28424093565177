.collapsable-box {
  background-color: var(--rojo_oscuro);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  width: calc(100vw - 32px);

  position: fixed;
  bottom: 80px;
  left: 16px;

  color: white;
}

.collapsable-box-head {
  text-align: center;
  padding: 16px 8px;
  font-size: 1rem;
  letter-spacing: 1px;
  color: white;
}

.ContainerQR {
  max-height: 0;
  overflow: hidden;
  transition: all .3s;
  padding: 0 8px;

}

.ContainerQR-Active {
  max-height: 100vh;
}

.collapsable-btn-action {
  text-align: center;
  font-size: 2rem;
  margin: 0 0 -12px 0;
  padding: 12px 0 0 0;
}

.ScanerBox {
  width: calc(100vw - 48px);
  height: calc(100vw - 48px);
  margin-bottom: 16px;
  overflow: hidden;
}

.succesResponse-img img {
  max-width: calc(100vw - 32px);
  margin-left: 16px;
}

.succesResponse-img button {
  max-width: calc(100vw - 32px);
  margin: 32px 16px 16px 16px;
}

/* === Desktop === */

.Desktop .collapsable-box {
  width: calc(40vw - 32px);

  position: fixed;
  bottom: 0;
  left: 50%;

  transform: translateX(-50%);

}

.Desktop .ScanerBox {
  width: calc(40vw - 32px);
  height: calc(400px + 8px);
  margin-bottom: 16px;
  overflow: hidden;
}

.Desktop .ScanerBox video {
  width: calc(100% - 16px);
}

.Desktop .succesResponse-img img {
  max-width: calc(40vw - 32px);
  margin: 0 -32px 0 auto;
  display: block;
}

.Desktop .succesResponse-img button {
  max-width: calc(100% - 32px);
}

.ErrorMessage h4 {
  text-align: center;
  font-size: 20px;
}

.ErrorMessage button {
  background-color: var(--rojo_claro);
  color: white;
  font-weight: normal;
  width: calc(100vw - 54px);
  font-size: 18px;
  font-family: sans-serif;
  margin-top: 8px;
}