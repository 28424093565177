.navbar-component {
  max-width: 1280px;
  margin: auto;

  z-index: 10;
  position: relative;
}

.navbar-component .navbar-box {
  background-color: var(--rojo_claro);
  width: calc(100vw - 16px);
  color: var(--blanco);
  overflow: hidden;


  position: fixed;
  bottom: 16px;
  left: 8px;

  border-radius: 16px;
  z-index: 1
}

.navbar-logo {
  width: 180px;
  margin: 8px auto;
}

.navbar-item {
  width: 100%;
  text-align: center;
  cursor: pointer;
  letter-spacing: 1px;
  padding: 8px 0;
  font-size: .75rem;

}

.navbar-item-active {
  background-color: var(--rojo_oscuro);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2) inset;
}

.navbar-item span::first-letter {
  margin-top: 8px;
  text-transform: capitalize;
}

.navbar-item:hover {
  background-color: var(--rojo_oscuro);
}

.navbar-item svg {
  font-size: 1.8rem;
  display: block;
  margin: auto;
  margin-bottom: 8px;
}

.navbar-item-back {
  background-color: transparent;
  color: var(--blanco);
  border-radius: 4px;
  padding: 12px;
  border: none;
}

.navbar-item-back i {
  margin: 2px 16px 0 8px;
  color: var(--blanco);
}


/* === ESCRITORIO === */

.navbar-component {
  max-width: 1224px;
  max-height: 66px;
  border-radius: 16px;
  margin-top: 16px;
}

.Desktop .navbar-component nav img {
  display: inline;
  margin: 0;
}

.Desktop .navbar-logueado {
  background-color: var(--rojo_claro);
}



.Desktop .navbar-component .navbar-box {
  position: initial;
  max-width: 420px;
  max-height: 66px;
  margin: 0;
  box-shadow: none;
  border-radius: 0;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.Desktop .navbar-logo {
  position: relative;
  top: -12px;
  left: 50%;
  transform: translate(-50%);

}

.userPuntaje {
  position: absolute;
  max-width: 300px;
  color: var(--blanco);
  padding: 4px 0 0 16px;
}

.userPuntaje h1 {
  font-size: 20px;
  margin-right: 16px;
}

.userPuntaje .emoji {
  width: 44px;
  height: 44px;
}

@media screen and (max-width:1024px) {
  .navbar-logo{
    visibility: hidden;
  }
}

@media screen and (max-width:500px){
  .navbar-item{
    letter-spacing: normal;
  }
}