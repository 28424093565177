.invalidInput {
    border: 1px solid red !important;
    background-color: rgb(255, 232, 232);
    color: red;
  }
  
  .errorMessage {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--rojo_claro);
    font-weight: bold;
  }