.Primera-img{
  max-width: 100%;
}

.Desktop .Primera-img{
  max-width: 50%;
  display: block;
  margin: 32px auto;
}

/*.Desktop .collapsable-box{
  display: none;
}*/