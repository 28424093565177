body {
  background-image: url('../../assets/Images/fondovioleta.avif');
  background-position: center;
  background-size: cover;
}

.flechaRoja {
  width: 50%;
  margin: auto;
  display: block;
}

.Desktop .home-page h1 {
  margin-top: 32px;
  margin-bottom: -10px;
}

.botonera {
  position: relative;
  z-index: 1;
}

.Desktop .botonera {
  display: grid;
  grid-template-columns: 500px 32px 500px;
  justify-content: center;
  align-items: center;
}

.Desktop .botonera button {
  font-size: 1.5rem;
  height: 64px;
  line-height: 64px;
}