:root {
  --rojo_claro: #CC1D1A;
  --rojo_oscuro: #800200;

  --violeta: #9900CC;
  --violeta_oscuro: #47005E;

  --amarillo: #D29D09;
  --negro: #000000;
  --blanco: #ffffff;
  --gris: #a3a3a3;
}

@font-face {
  font-family: fuenteFidelizar;
  src: url(../Fonts/ariblk.ttf);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  list-style: none;
  text-decoration: none;
  font-family: fuenteFidelizar;
}

*::first-letter {
  text-transform: capitalize !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  min-height: 100vh;
  background: var(--rojo_oscuro);
  /*background-image: url(./assets/img/fondovioleta.avif);*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

body::-webkit-scrollbar {
  display: none;
}

/* --- colores --- */

.bg-violeta {
  background: var(--violeta);
}

.bg-rojo {
  background: #ff0000;
}

.bg-gris {
  background: var(--gris);
}

.txt-white {
  color: var(--blanco);
}

/* --- Animaciones --- */

.Giro {
  animation: Giro360 0.5s infinite linear;
}

@keyframes Giro360 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.Aparece-animation {
  animation: aparece 0.3s linear;
}

@keyframes aparece {
  0% {
    margin-top: 5rem;
    opacity: 1;
  }

  100% {
    margin-top: 0;
    opacity: 1;
  }
}

/* --- FLEXBOX --- */

.text-white {
  color: var(--blanco);
}

.text-center {
  text-align: center;
}

/* --- FLEXBOX --- */

.rowFlex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.spaceBetween {
  justify-content: space-between;
}

.spaceEnd {
  justify-content: flex-end;
}


.alignStart {
  align-items: flex-start;
}

.col-2 {
  display: grid;
  grid-template-columns: calc(50% - 16px) calc(50% - 16px);
  justify-content: space-between;
}

.width-100 {
  width: 100%;
}

.width-50 {
  width: 50%;
}

/* --- MARGENES Y PADDINGS --- */

.margin-auto {
  margin: auto;
}

.margin-reset {
  margin: 0 0 0 0;
}

.margin-4 {
  margin: 4px;
}

.margin-8 {
  margin: 8px;
}

.margin-16 {
  margin: 16px;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-16 {
  margin-top: 16px;
}

/* --- ------------------------ --- */

.card-component {
  background-color: white;
  padding: 16px;
  margin: 16px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(49, 49, 49, 0.2)
}

/* --- ------------------------ --- */

.text-center {
  text-align: center;
}

/* --- GENERAL INPUTS --- */

fieldset {
  position: relative;
  border-radius: 4px;
  padding: 8px 0;
  border: none;
  /* 1px solid #ced4da */
}

fieldset input {
  height: 44px;
  line-height: 44px;
  border: none;
  background-color: transparent;
  padding: 0 12px;
  background-color: #f5f5f5;
  width: 100%;
  border-radius: 4px;
  font-size: 16px;
  border: 1px solid #f8f8f8 !important;

  font-family: Arial, Helvetica, sans-serif;
}

fieldset input:focus {
  outline: none;
}

fieldset input::placeholder {
  color: #a9b4bd
}

fieldset label {
  display: block;
  color: #2b3844;
  margin-bottom: 8px;
}

button,
fieldset button,
input[type='submit'] {
  height: 64px;
  line-height: 60px;
  border: none;
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.4rem;

  text-transform: uppercase;
  box-shadow: 0 1px 5px rgba(19, 19, 19, 0.3)
}

button:disabled,
fieldset button:disabled,
input[type='submit']:disabled {
  cursor: no-drop;
}

.error-message {
  color: crimson;
  margin: 8px 0 0 0 !important;
  font-style: italic;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}

/* --- ------------------------ --- */

.link-text {
  font-weight: normal;
  font-size: 1.4rem;
  cursor: pointer;
}

.emoji {
  width: 64px;
  height: 64px;
  background-image: url('https://img.icons8.com/emoji/192/money-mouth-face.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.message-box button {
  max-width: calc(100% - 32px);
  margin: 16px;
}

/* --- ------------------------ --- */

.loading-icon {
  color: white;
  font-size: 2rem;
  display: block;
  text-align: center;
}

.ModalLoading, .ModalPremio {
  width: 25vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  display: grid;
  justify-content: center;
  align-items: center;
}

.ModalLoading{
  background: radial-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8));
  backdrop-filter: blur(20px);
  width: 100vw;
  z-index: 10;
}

@media screen and (max-width:1240px) {
  .ModalPremio {
    background: radial-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8));
    backdrop-filter: blur(20px);
    width: 100vw;
    left: 0;
    z-index: 10;
  }
}

.ModalLoading .iconLoading {
  font-size: 3rem;
  color: white;
}

.canje-messaje h4 {
  font-size: 1.4rem;
  margin-bottom: 16px;
  text-align: center;
}

.cancelar-modal {
  color: white;
  text-align: center;
}