.registro-page .box-container {
  box-shadow: 0 0 0 100vw var(--rojo_oscuro),
    0 0 0 100vw var(--rojo_oscuro) inset;
}

.card-component fieldset:first-child {
  padding-top: 0;
}

.card-component {
  border-radius: 12px;
}

.registro-page {
  margin-top: -16px;
  padding-top: 16px;
  min-height: calc(100vh + 16px);
  overflow-y: scroll;
}

.registro-page::-webkit-scrollbar {
  width: 0;
  height: 0;

}

.registro-page .logo-login {
  width: 20vh;
  display: block;
  margin: 16px auto;
  transition: all .3s;
}

.Desktop .registro-page .box-container {
  max-width: 480px;
  margin: auto;
}

.Desktop .registro-page .logo-login {
  width: 10vw;
}

.forgetPass {
  text-align: center;
  font-size: 16px;
  text-decoration: underline;
}

.card-component img {
  max-width: 100%;
}

.card-component .message {
  text-align: center;
  font-size: 1.5rem;
}

.loading-icon {
  margin: 12px 8px -24px 8px;
}